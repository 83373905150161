import React from 'react'
import UiLink from '@lib/ui/core/Link'
import { Link as GatsbyLink } from 'gatsby'

// eslint-disable-next-line prefer-arrow-callback
const Link = React.forwardRef(function Link(props, ref) {
  return <UiLink component={GatsbyLink} ref={ref} {...props} />
})

export default Link
