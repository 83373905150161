/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';

export default {
  search: {
    history: [],
  },
  updateSearchHistory: action((state, payload) => {
    const existed = state.search.history.findIndex((record) => record.name === payload);
    if (existed > -1) state.search.history.splice(existed, 1)
    state.search.history.unshift({ name: payload });
    if (state.search.history.length > 5) state.search.history.pop();
  }),
  deleteSearchHistory: action((state, payload) => {
    const existed = state.search.history.findIndex((record) => record.name === payload);
    state.search.history.splice(existed, 1)
  }),
};
