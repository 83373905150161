import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'offer_subcategory.uid',
  'offer_subcategory.prismicId',
  'offer_subcategory.name',
]

const getSearchCategories = (ids) => {

  let predicates = [
    Prismic.Predicates.at('document.type', 'offer_subcategory'),
    Array.isArray(ids) ? Prismic.Predicates.in('document.id', ids) : Prismic.Predicates.at('document.id', ids)
  ]

  return query(
    predicates,
    {
      fetch: fields,
    },
  )
}

export default getSearchCategories
