import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'neighborhood.id',
  'neighborhood.name',
]

// const linkedFields = [
//   'offer_category.name',
//   'offer_subcategory.name',
//   'offer_subcategory.offer_category',
//   'merchant.name',
//   'bank.name',
//   'credit_card.name',
//   'credit_card.card_image',
//   'credit_card.bank',
//   'tag.name',
// ]

const getRegions = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    // offerCategoryId,
    // offerSubcategoryId,
    // tagId,
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'region'),
  ]

  // if (offerCategoryId) {
  //   predicates = [
  //     ...predicates,
  //     Prismic.Predicates.at('my.offer.offer_category', offerCategoryId),
  //   ]
  // }

  // if (offerSubcategoryId) {
  //   predicates = [
  //     ...predicates,
  //     Prismic.Predicates.at('my.offer.offer_subcategory', offerSubcategoryId),
  //   ]
  // }

  // if (tagId) {
  //   predicates = [
  //     ...predicates,
  //     Prismic.Predicates.at('my.offer.tagged.tag', tagId),
  //   ]
  // }

  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }

  return query(
    predicates,
    {
      orderings: '[my.region.sequence desc, my.region.name]',
      fetch: fields,
      // fetchLinks: linkedFields,
      ...options,
    },
    {
      // parseDate: dateFields,
      // parseRichText: richTextFields,
      ...extensions,
    }
  )
}

export default getRegions
