import { createMuiTheme } from '@material-ui/core/styles'
import createShadows from '@utils/shadows'

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1132,
  },
};

const defaultTheme = createMuiTheme({ breakpoints });

const theme = createMuiTheme({
  breakpoints,
  shadows: createShadows("#c2c2c2"),
  typography: {
    fontFamily: 'Noto Sans TC',
    h1: {
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontWeight: '500',
      fontSize: '1.5rem',
      lineHeight: 1.5,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '1.25rem',
      },
    },
    h3: {
      fontWeight: '500',
      fontSize: '1.25rem',
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontWeight: '700',
      fontSize: '0.875rem',
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("sm")]: {
        fontWeight: '700',
        fontSize: '1rem',
      },
    },
    subtitle1: {
      fontWeight: '400',
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '1rem',
      },
    },
    subtitle2: {
      fontWeight: '700',
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.up("sm")]: {
        fontWeight: '400',
        fontSize: '1rem',
      },
    },
    body1: {
      fontWeight: '400',
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '1rem',
      },
    },
    body2: {
      fontWeight: '400',
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: '0.875rem',
      },
    },
  },
  palette: {
    primary: {
      light: '#fffddb',
      main: '#fde604',
      dark: '#fde604',
      // dark: '#c6b400',
      contrastText: '#000',
    },
    secondary: {
      light: '#ffcb4c',
      main: '#c99a13',
      dark: '#946c00',
      contrastText: '#000',
    },
    error: {
      light: '#ff7a70',
      main: '#F54444',
      dark: '#bb001c',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#000',
      secondary: '#aaa',
    },
    divider: '#ddd',
    background: {
      default: '#fff',
      gray: '#f5f5f5',
      circleBtn: '#ffe600',
      footer: '#2f3639',
    },
    actionText: {
      active: 'rgba(201, 154, 19, 1)',
      hover: 'rgba(201, 154, 19, .6)',
      hoverOpacity: 0.6,
      selected: 'rgba(201, 154, 19, .8)',
      selectedOpacity: 0.8,
      disabled: 'rgba(201, 154, 19, .3)',
      disabledBackground: 'rgba(201, 154, 19, .3)',
      disabledOpacity: 0.3,
      focus: 'rgba(201, 154, 19, .8)',
      focusOpacity: 0.8,
      activatedOpacity: 0.8,
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: "3.125rem",
        [defaultTheme.breakpoints.up("sm")]: {
          minHeight: "3.75rem",
        },
      },
      gutters: {
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: ".25rem",
          paddingRight: ".25rem",
        },
      },
    },
    MuiDialog: {
      paper: {
        [defaultTheme.breakpoints.down("xs")]: {
          margin: "1rem",
        },
      },
    },
  },
});

export default theme;
