/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy'
import parseCreditCard from '@services/prismic/helpers/parse-credit-card'

export default {
  // State
  list: [],

  // Action
  update: action((state, payload) => {
    state.list = parseCreditCard(payload)
  }),
}
