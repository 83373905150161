import React from 'react'
import styled from 'styled-components'
import Snackbar from '@lib/ui/core/Snackbar'
import Alert from '@lib/ui/core/Alert'

// eslint-disable-next-line prefer-arrow-callback
const Message = styled(React.forwardRef(function Message({ onClose = () => null, severity, message, ...props }, ref) {
  return (
    <Snackbar
      ref={ref}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...props}
    >
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}))`
`

Message.defaultProps = {
  severity: 'info',
}

export default Message
