import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'neighborhood.id',
  'neighborhood.name',
  'neighborhood.region',
]

const linkedFields = [
  'region.name',
]

// const dateFields = [
//   ['to'],
// ]

// const richTextFields = [
//   {
//     path: ['short_description'],
//     asText: true,
//     asHtml: false,
//     asRaw: false,
//   },
// ]

const getNeighborhoods = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    regionId,
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'neighborhood'),
  ]

  if (regionId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.neighborhood.region', regionId),
    ]
  }


  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }

  return query(
    predicates,
    {
      orderings: '[my.neighborhood.sequence desc, my.neighborhood.name]',
      fetch: fields,
      fetchLinks: linkedFields,
      ...options,
    },
    {
      // parseDate: dateFields,
      // parseRichText: richTextFields,
      ...extensions,
    }
  )
}

export default getNeighborhoods
