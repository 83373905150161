/* eslint-disable no-param-reassign */
import { persist, action, debug } from 'easy-peasy'
import { pipe, uniqBy, prop } from 'ramda'

export default persist({
  creditCards: [],
  updateCreditCards: action((state, payload) => {
    state.creditCards = payload
  }),
  addCreditCard: action((state, payload) => {
    const convert = pipe(uniqBy(prop('prismicId')))
    const oldCreditCards = debug(state.creditCards)
    const newCreditCards = convert([...oldCreditCards, ...payload])
    state.creditCards = newCreditCards
  }),
  deleteCreditCard: action((state, payload) => {
    const cardIndex = state.creditCards.findIndex((card) => card.prismicId === payload);
    state.creditCards.splice(cardIndex, 1)
  }),
  removeAllCreditCard: action((state) => {
    state.creditCards = []
  }),
},
{
  storage: 'localStorage',
})
