/* eslint-disable import/prefer-default-export */
import wrap from "./root-wrapper"

export const wrapRootElement = wrap;

/*
  workaround to gatsby & react hydration
  issue: https://github.com/gatsbyjs/gatsby/issues/17914
*/
const ReactDOM = require('react-dom')

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
