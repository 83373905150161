/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import axios from 'axios'
// import urljoin from 'url-join'
import { navigate } from '@reach/router'
import { searchOffers } from "@services/prismic"
import qs from 'qs'
import { withPrefix } from "gatsby"
import urljoin from 'url-join'

const { CancelToken } = axios;


export default {
  isLoading: false,
  searchStatus: false,
  autocompleteCancelToken: undefined,
  searchPayload: {
    creditcard_id: [],
    q: undefined,
    offer_subcategory_id: [],
    neighborhood_id: [],
    page: 0,
  },
  searchResult: [],
  cardChosen: [],
  firstCardInfo: [],
  autocompleteSuggestion: [],
  showBankDrawer: false,
  showSearchDrawer: false,

  showFiltersDrawer: false,
  showSubcatDrawer: false,
  subcatShown: [],
  subcatChosen: [],
  // subcatPayload: [],
  showFilteredBanner: false,

  showRegionDrawer: false,
  showNeighborhoodDrawer: false,
  neighborhoodShown: [],
  neighborhoodChosen: [],
  // neighborhoodPayload: [],

  updateFilteredBanner: action((state, payload) => {
    state.showFilteredBanner = (payload)
  }),
  updateCardPayload: action((state, payload) => {
    state.searchPayload.creditcard_id = payload;
  }),
  updateKeyword: action((state, payload) => {
    state.searchPayload.q = payload;
  }),

  updatePageNumber: action((state, payload) => {
    state.searchPayload.page = payload;
  }),

  updateCardChosen: action((state, payload) => {
    state.cardChosen = payload;
  }),
  updateFirstCardInfo: action((state, payload) => {
    state.firstCardInfo = payload;
  }),
  toggleBankDrawer: action((state, payload) => {
    state.showBankDrawer = (payload)
  }),
  toggleFiltersDrawer: action((state, payload) => {
    state.showFiltersDrawer = (payload)
  }),
  toggleSearchDrawer: action((state, payload) => {
    state.showSearchDrawer = payload;
  }),

  toggleSubcatDrawer: action((state, payload) => {
    state.showSubcatDrawer = payload;
  }),
  updateSubcatShown: action((state, payload) => {
    state.subcatShown = payload;
  }),
  updateSubcatChosen: action((state, payload) => {
    state.subcatChosen = payload;
  }),
  updateSubcatPayload: action((state, payload) => {
    state.searchPayload.offer_subcategory_id = payload;
  }),

  toggleRegionDrawer: action((state, payload) => {
    state.showRegionDrawer = (payload)
  }),
  toggleNeighborhoodDrawer: action((state, payload) => {
    state.showNeighborhoodDrawer = payload;
  }),
  updateNeighborhoodShown: action((state, payload) => {
    state.neighborhoodShown = payload;
  }),
  updateNeighborhoodChosen: action((state, payload) => {
    state.neighborhoodChosen = payload;
  }),
  updateNeighborhoodPayload: action((state, payload) => {
    state.searchPayload.neighborhood_id = payload;
  }),

  reset: action((state) => {
    state.searchPayload = {
      creditcard_id: [],
      q: undefined,
      offer_subcategory_id: [],
      neighborhood_id: [],
      page: undefined,
    };
    state.firstCardInfo = [];
    state.searchResult = [];
    state.searchStatus = false;
  }),
  addSearchResult: action((state, payload) => {
    state.searchResult = (payload);
  }),


  updateSuggestion: action((state, payload) => {
    state.autocompleteSuggestion = payload;
  }),
  updateCancelToken: action((state, payload) => {
    state.autocompleteCancelToken = payload;
  }),
  updateSearchStatus: action((state, payload) => {
    state.searchStatus = payload;
  }),
  updateIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  // fetchAutocomplete: thunk(async (actions, payload, helpers) => {
  fetchAutocomplete: thunk(async (actions, payload) => {
    // const { getStoreState } = helpers;
    // const { offerSearch: { autocompleteCancelToken: previousSource } } = getStoreState();

    // actions.updateKeyword(payload);

    // if (previousSource) {
    //   previousSource.cancel();
    // }

    const source = CancelToken.source();
    actions.updateCancelToken(source);
    const url = urljoin(process.env.GATSBY_API_URL, '/offers/search/autocomplete')

    try {
      const { data } = await axios.get(url, {
        params: {
          q: payload,
        },
        cancelToken: source.token,
      });
      actions.updateSuggestion(data);
    } catch (e) {
      actions.updateSuggestion([]);
    }
  }),
  fetchSearchResult: thunk(async (actions, payload, helpers) => {
    // navigate('/search');
    actions.updateIsLoading(true);
    actions.toggleRegionDrawer(false);
    actions.toggleFiltersDrawer(false);
    actions.toggleBankDrawer(false);
    actions.toggleSearchDrawer(false);
    actions.toggleSubcatDrawer(false);
    actions.toggleNeighborhoodDrawer(false);

    const { getStoreState, getStoreActions } = helpers;
    const { offerSearch: { searchPayload } } = getStoreState();
    const { session: { updateSearchHistory } } = getStoreActions();
    const { q } = searchPayload;
    const filterParams = qs.stringify(searchPayload, { encode: false, arrayFormat: 'repeat' })
    navigate(withPrefix(urljoin('/offers', `?${filterParams}`)));
    return;
    try {
      // const url = urljoin(process.env.GATSBY_API_URL, '/offers/search')
      // const { data } = await axios.get(url, {
      //   params: {
      //     creditcard_id: creditcard_id.length > 0 ? creditcard_id : undefined,
      //     q,
      //     page: 1,
      //     page_size: 10,
      //   },
      // })
      actions.updateIsLoading(true);
      const richTextFields = [
        {
          path: ['short_description'],
          asText: true,
          asHtml: false,
          asRaw: false,
        },
      ]
      const { result } = await searchOffers({
        pageSize: 12,
        page: 1,
      },
      {
        parseRichText: richTextFields,
      })
      actions.updateSearchStatus(true);
      actions.addSearchResult(result);
      actions.updateSuggestion([]);
      if (q && q !== '') updateSearchHistory(q);
      actions.updateIsLoading(false);
    } catch (err) {
      actions.updateSearchStatus(true);
      actions.addSearchResult([]);
      actions.updateSuggestion([]);
      if (q && q !== '') updateSearchHistory(q);
      actions.updateIsLoading(false);
    }
  }),
};
