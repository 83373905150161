import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'interest.uid',
  'interest.is_featured',
]

const getInterests = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'interest'),
  ]

  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }

  return query(
    predicates,
    {
      fetch: fields,
      ...options,
    },
    {
      ...extensions,
    }
  )
}

export default getInterests
