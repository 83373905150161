import React from "react"
import PropTypes from "prop-types"
import { StoreProvider } from "easy-peasy"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import { DFPSlotsProvider } from 'react-dfp';

import store from "./src/store"
import theme from "./src/theme"

const wrapWithProvider = ({ element }) => (
  <StoreProvider store={store}>
    <DFPSlotsProvider dfpNetworkId="14112232">
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </MuiThemeProvider>
    </DFPSlotsProvider>
  </StoreProvider>
)

wrapWithProvider.propTypes = {
  element: PropTypes.node.isRequired,
}

export default wrapWithProvider
