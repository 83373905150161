import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'credit_card.id',
  'credit_card.name',
  'credit_card.bank',
  'credit_card.card_image',
]

const linkedFields = [
  'bank.name',
]

// const dateFields = [
//   ['to'],
// ]

// const richTextFields = [
//   {
//     path: ['short_description'],
//     asText: true,
//     asHtml: false,
//     asRaw: false,
//   },
// ]

const getCreditCards = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    bankId,
    displayOnOffer,
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'credit_card'),
  ]

  if (bankId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.credit_card.bank', bankId),
    ]
  }

  if (displayOnOffer) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.credit_card.display_on_offer_page', true),
    ]
  }

  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }

  return query(
    predicates,
    {
      orderings: '[my.credit_card.sequence desc, my.credit_card.name]',
      fetch: fields,
      fetchLinks: linkedFields,
      ...options,
    },
    {
      // parseDate: dateFields,
      // parseRichText: richTextFields,
      ...extensions,
    }
  )
}

export default getCreditCards
