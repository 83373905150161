import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'offer.id',
  'offer.offer_category',
  'offer.offer_subcategory',
  'offer.featured_images',
  'offer.merchants',
  'offer.banks',
  'offer.credit_cards',
  'offer.short_description',
  'offer.tagged',
  'offer.title',
  'offer.to',
]

const linkedFields = [
  'offer_category.name',
  'offer_subcategory.name',
  'offer_subcategory.offer_category',
  'merchant.name',
  'bank.name',
  'credit_card.name',
  'credit_card.card_image',
  'credit_card.bank',
  'tag.name',
]

const dateFields = [
  ['to'],
]

const richTextFields = [
  {
    path: ['short_description'],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
]

const getOffers = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    offerCategoryId,
    offerSubcategoryId,
    tagId,
    lastPublicationDate,
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'offer'),
  ]

  if (offerCategoryId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.offer.offer_category', offerCategoryId),
    ]
  }

  if (offerSubcategoryId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.offer.offer_subcategory', offerSubcategoryId),
    ]
  }

  if (tagId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.offer.tagged.tag', tagId),
    ]
  }

  if (lastPublicationDate) {
    const beforeDate = new Date(lastPublicationDate);
    beforeDate.setSeconds(beforeDate.getSeconds() + 1);
    predicates = [
      ...predicates,
      Prismic.Predicates.dateBefore('document.last_publication_date', beforeDate),
    ]
  }

  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }


  const today = new Date();
  predicates = [
    ...predicates,
    Prismic.Predicates.dateAfter('my.offer.to', today),
  ]

  return query(
    predicates,
    {
      orderings: '[my.offer.date desc, document.last_publication_date desc]',
      fetch: fields,
      fetchLinks: linkedFields,
      ...options,
    },
    {
      parseDate: dateFields,
      parseRichText: richTextFields,
      ...extensions,
    }
  )
}

export default getOffers
