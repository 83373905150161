/* eslint-disable camelcase */
import { path, curry, assocPath } from 'ramda'
import axios from 'axios'
// import store from '@store'
import urljoin from 'url-join'
// import { useStoreState } from 'easy-peasy'
import dateParser from './helpers/common/date-parser'
import richTextParser from './helpers/common/rich-text-parser'
import parser from './helpers/parser'
import renameIdToPrismicId from './helpers/rename-id-to-prismic-id'
// import urljoin from 'url-join'

const curryPath = curry(path)

const searchTags = async (options = {}, extensions = {}) => {
  const { parserOptions, parseDate, parseRichText } = extensions
  const isParseDate = Array.isArray(parseDate)
  const isParseRichText = Array.isArray(parseRichText)
  const isParseField = isParseDate || isParseRichText
  const url = urljoin(process.env.GATSBY_API_URL, '/offers/addon')
  const response = await axios.get(url, {
    params: {
      tag_id: options.tagId,
      page: options.page,
      page_size: options.pageSize,
    },
  })

  const { data, headers } = response;
  const totalCount = headers['x-total-count']

  let parsedResults = parser(data, {
    transform: renameIdToPrismicId,
    ...parserOptions,
  })

  if (Array.isArray(parsedResults) && isParseField) {
    parsedResults = parsedResults.map((result) => {
      let parsedResult = result

      if (isParseDate) {
        parseDate.forEach((datePath) => {
          const old = path(datePath, parsedResult)
          parsedResult = assocPath(datePath, dateParser(old), parsedResult)
        })
      }
      if (isParseRichText) {
        parseRichText.forEach(({ path: richTextPath, asText, asHtml, asRaw }) => {
          const old = path(richTextPath, parsedResult)
          parsedResult = assocPath(richTextPath, richTextParser(old, { asText, asHtml, asRaw }), parsedResult)
        })
      }

      return parsedResult
    })
  }

  if (Array.isArray(parseDate)) {
    parseDate.map((datePath) => curryPath(datePath))
  }

  return {
    totalCount,
    results: parsedResults,
  }
}

export default searchTags
