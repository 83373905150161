import { get, post, patch, del } from './request'

export const getUserSummary = () => get('/users/self/summary', {}, true)

export const getUserProfile = () => get('/users/self/profile', {}, true)

export const updateUserProfile = (data) => patch('/users/self/profile', data, true)

export const getInterestsList = () => get('/interests')

export const getUserInterests = () => get('/users/self/interests', {}, true)

export const addInterest = (data) => post('/users/self/interests', data, true)

export const addInterestBatch = (data) => post('/user/self/interests/batch', data, true)

export const delInterest = (id) => del(`/users/self/interests/${id}`, {}, true)

export const getCreditCardsList = () => get('/creditcards')

export const getUserCreditCards = () => get('/users/self/creditcards', {}, true)

export const addCreditCard = (data) => post('/users/self/creditcards', data, true)

export const addCreditCardBatch = (data) => post('/users/self/creditcards/batch', data, true)

export const delCreditCard = (id) => del(`/users/self/creditcards/${id}`, {}, true)
