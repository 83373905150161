/* eslint-disable no-param-reassign */
import { action, thunk, debug } from 'easy-peasy'
import { getUserProfile, addInterest, delInterest } from '@services/api/user'
import { pipe, uniqBy, prop } from 'ramda'

export default {
  email: "",
  profile: {
    nickname: '',
    avatar: '',
  },
  creditCards: [],
  interests: [],

  // Action
  updateEmail: action((state, payload) => {
    state.email = payload
  }),
  updateProfile: action((state, payload) => {
    state.profile = payload
  }),
  updateName: action((state, payload) => {
    state.profile.nickname = payload
  }),
  updateAvatar: action((state, payload) => {
    state.profile.avatar = payload
  }),
  updateCreditCards: action((state, payload) => {
    state.creditCards = payload
  }),
  addCreditCards: action((state, payload) => {
    const convert = pipe(uniqBy(prop('id')))
    const oldCreditCards = debug(state.creditCards)
    const newCreditCards = convert([...oldCreditCards, ...payload])
    state.creditCards = newCreditCards
  }),
  deleteCreditCard: action((state, payload) => {
    const cardIndex = state.creditCards.findIndex((card) => card.id === payload);
    state.creditCards.splice(cardIndex, 1)
  }),

  updateInterests: action((state, payload) => {
    state.interests = payload
  }),

  cleanUserData: action((state) => {
    state.email = ""
    state.profile = { nickname: '', avatar: '' }
    state.creditCards = []
    state.interests = []
  }),

  // Thunk
  addInterest: thunk(async (actions, payload, helper) => {
    const res = await addInterest({ interest_id: payload.id })
    const interests = await helper.getStoreState().user.interests
    if (interests.every((interest) => interest.content.id !== payload.id)) {
      actions.updateInterests([...interests, res.data])
    }
  }),

  delInterest: thunk(async (actions, payload, helper) => {
    const res = await delInterest(payload.id)
    const interests = await helper.getStoreState().user.interests
    if (res.data.status) {
      actions.updateInterests(interests.filter((interest) => interest.id !== payload.id))
    }
  }),

  loadProfile: thunk(async (actions, payload, helper) => {
    const profile = await helper.getStoreState().user.profile
    if (!profile.nickname || !profile.avatar) {
      const res = await getUserProfile()
      actions.updateProfile(res.data)
    }
  }),
};
