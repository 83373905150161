import React, { useState, useEffect } from "react"
import Link from '@components/Link'
import { RichText } from 'prismic-dom'
import { compose, not, has } from 'ramda'
import { linkResolver } from '@utils/link-resolver'

const richTextParser = (data, options) => {
  const { asText = false, asHtml = false, asRaw = false } = options || {}
  const result = {}
  const htmlSerializer = function (type, element, content, children, key) {
    if (element.link_type === 'Document' && element.type === 'offer')
      return (
        <Link key={key} href={urljoin('/', element.data.offer_subcategory.uid, element.uid)}>
          {content}
        </Link>
      )
    if (element.link_type === 'Document' && element.type === 'post')
      return (
        <Link key={key} href={urljoin('/', element.data.post_subcategory.uid, element.uid)}>
          {content}
        </Link>
      )
    if (element.link_type === 'Web') {
      return (
        <Link key={key} rel={element.target ? 'noopener' : undefined} target={element.target} href={element.url}>
          {content}
        </Link>
      )
    }
  }

  if (!Array.isArray(data) || (!asText && !asHtml && !asRaw)) return data

  if (asText) {
    result.text = RichText.asText(data)
  }

  if (asHtml) {
    const fixedData = data.map((item) => {
      if(compose(not, has('text'))(item) && compose(not, has('spans'))(item)) 
        return { ...item, text: "", spans: [] }
      if(compose(not, has('spans'))(item)) {
        return { ...item, spans: [] }
      } else
        return item
    })
    result.html = RichText.asHtml(fixedData, linkResolver, htmlSerializer)
  }

  if (asRaw) {
    result.raw = data
  }

  return result
}

export default richTextParser
