/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy'

export default {
  lastPopup: "",
  showPopup: true,
  hidePopup: action((state) => {
    state.showPopup = false
  }),
  updateShowPopup: action((state) => {
    state.showPopup = true
  }),
  updatelLastPopup: action((state, payload) => {
    state.lastPopup = payload
  }),
}
