import { path, curry, assocPath } from 'ramda'
import dateParser from './common/date-parser'
import richTextParser from './common/rich-text-parser'
import client from './client'
import parser from './parser'
import renameIdToPrismicId from './rename-id-to-prismic-id'

const curryPath = curry(path)

const prismicQuery = async (predicates, options = {}, extensions = {}) => {
  const { parserOptions, parseDate, parseRichText } = extensions
  const isParseDate = Array.isArray(parseDate)
  const isParseRichText = Array.isArray(parseRichText)
  const isParseField = isParseDate || isParseRichText

  const response = await client.query(predicates, options)
  const { total_results_size: totalCount, results } = response
  let parsedResults = parser(results, {
    transform: renameIdToPrismicId,
    ...parserOptions,
  })

  if (Array.isArray(parsedResults) && isParseField) {
    parsedResults = parsedResults.map((result) => {
      let parsedResult = result

      if (isParseDate) {
        parseDate.forEach((datePath) => {
          const old = path(datePath, parsedResult)
          parsedResult = assocPath(datePath, dateParser(old), parsedResult)
        })
      }
      if (isParseRichText) {
        parseRichText.forEach(({ path: richTextPath, asText, asHtml, asRaw }) => {
          const old = path(richTextPath, parsedResult)
          parsedResult = assocPath(richTextPath, richTextParser(old, { asText, asHtml, asRaw }), parsedResult)
        })
      }

      return parsedResult
    })
  }

  if (Array.isArray(parseDate)) {
    parseDate.map((datePath) => curryPath(datePath))
  }

  return {
    totalCount,
    results: parsedResults,
  }
}

export default prismicQuery
