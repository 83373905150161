import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'credit_card.id',
  'credit_card.name',
  'credit_card.bank',
  'credit_card.card_image',
]

const getSearchCreditCards = (ids) => {

  let predicates = [
    Prismic.Predicates.at('document.type', 'credit_card'),
    Array.isArray(ids) ? Prismic.Predicates.in('document.id', ids) : Prismic.Predicates.at('document.id', ids)
  ]

  return query(
    predicates,
    {
      fetch: fields,
    },
  )
}

export default getSearchCreditCards
