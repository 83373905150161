/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import axios from 'axios'
import { getToken, setToken, getRefreshToken } from '@utils/auth'
import { redirectToSSOURL, toast } from '@utils/url'

const refreshToken = () => post('/auth/refresh', { refresh_token: getRefreshToken() }, true)

let isRefreshing = false
let requests = []

const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 30000,
  headers: {
    'content-type': 'application/json;charset=UTF-8',
  },
})

instance.interceptors.response.use((response) => {
  if (response.status === 200) {
    return Promise.resolve(response)
  }
  return Promise.reject(response.data.errors.error)
}, (error) => {
  if (!error.response) {
    return Promise.reject(error)
  }
  if (error.response.status === 401 && !error.config.url.includes('/auth/refresh')) { // Expired token or Invalid token
    const { config } = error
    if (!isRefreshing) {
      isRefreshing = true
      return refreshToken().then((res) => {
        const { access_token } = res.data
        setToken(access_token)
        config.headers.Authorization = `Bearer ${access_token}`
        requests.forEach((cb) => cb(access_token))
        requests = []
        return instance(config)
      }).catch((err) => {
        toast('抱歉，您的登錄狀態已失效，請重新登錄！', redirectToSSOURL)
        return Promise.reject(err)
      }).finally(() => {
        isRefreshing = false
      })
    }
    return new Promise((resolve) => {
      requests.push((token) => {
        config.headers.Authorization = `Bearer ${token}`
        resolve(instance(config))
      })
    })
  }
  // other error status code
  return Promise.reject(error)
})

const setHeaderToken = (isNeedToken) => {
  const accessToken = isNeedToken ? getToken() : null
  if (isNeedToken) {
    if (!accessToken) {
      redirectToSSOURL()
    }
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }
}

export const get = (url, params = {}, isNeedToken = false) => {
  setHeaderToken(isNeedToken)
  return instance({
    method: 'get',
    url,
    params,
  })
}

export const post = (url, params = {}, isNeedToken = false) => {
  setHeaderToken(isNeedToken)
  return instance({
    method: 'post',
    url,
    data: params,
  })
}

export const patch = (url, params = {}, isNeedToken = false) => {
  setHeaderToken(isNeedToken)
  return instance({
    method: 'patch',
    url,
    data: params,
  })
}

export const del = (url, params = {}, isNeedToken = false) => {
  setHeaderToken(isNeedToken)
  return instance({
    method: 'delete',
    url,
    data: params,
  })
}
