// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = (doc) => {
  // const getPostCategoryUidByPost = (post) => {
  //   if (post.data && post.data.post_category && post.data.post_category.uid) {
  //     return post.data.post_category.uid;
  //   }
  //   if (post.post_category && post.post_category.uid) {
  //     return post.post_category.uid;
  //   }
  //   return null;
  // };
  const getPostSubcategoryUidByPost = (post) => {
    if (post.data && post.data.post_subcategory && post.data.post_subcategory.uid) {
      return post.data.post_subcategory.uid;
    }
    if (post.post_subcategory && post.post_subcategory.uid) {
      return post.post_subcategory.uid;
    }
    return null;
  };

  // const getOfferCategoryUidByOffer = (offer) => {
  //   if (offer.data && offer.data.offer_category && offer.data.offer_category.uid) {
  //     return offer.data.offer_category.uid;
  //   }
  //   if (offer.offer_category && offer.offer_category.uid) {
  //     return offer.offer_category.uid;
  //   }
  //   return null;
  // };

  const getOfferSubcategoryUidByOffer = (offer) => {
    if (offer.data && offer.data.offer_subcategory && offer.data.offer_subcategory.uid) {
      return offer.data.offer_subcategory.uid;
    }
    if (offer.offer_subcategory && offer.offer_subcategory.uid) {
      return offer.offer_subcategory.uid;
    }
    return null;
  };

  const getPostCategoryUidBySubcategory = (subcategory) => {
    if (subcategory.data && subcategory.data.post_category && subcategory.data.post_category.uid) {
      return subcategory.data.post_category.uid;
    }
    if (subcategory.post_category && subcategory.post_category.uid) {
      return subcategory.post_category.uid;
    }
    return null;
  };

  const getOfferCategoryUidBySubcategory = (subcategory) => {
    if (subcategory.data && subcategory.data.offer_category && subcategory.data.offer_category.uid) {
      return subcategory.data.offer_category.uid;
    }
    if (subcategory.offer_category && subcategory.offer_category.uid) {
      return subcategory.offer_category.uid;
    }
    return null;
  };

  const getBankUidByCreditCard = (creditcard) => {
    if (creditcard.data && creditcard.data.bank && creditcard.data.bank.uid) {
      return creditcard.data.bank.uid;
    }
    if (creditcard.bank && creditcard.bank.uid) {
      return creditcard.bank.uid;
    }
    return null;
  };
  if (doc && doc.type === 'post') {
    // return `/${doc.prismicId}/${getPostCategoryUidByPost(doc) || "general"}/${doc.uid}`;
    return `/${getPostSubcategoryUidByPost(doc) || "general"}/${doc.uid}`;
  }

  if (doc && doc.type === 'offer') {
    // return `/${doc.prismicId}/${getOfferCategoryUidByOffer(doc) || "general"}/${getOfferSubcategoryUidByOffer(doc) || "general"}/${doc.uid}`;
    return `/${getOfferSubcategoryUidByOffer(doc) || "general"}/${doc.uid}`;
  }

  if (doc && doc.type === 'credit_card') {
    // return `/${doc.prismicId}/${getOfferCategoryUidByOffer(doc) || "general"}/${getOfferSubcategoryUidByOffer(doc) || "general"}/${doc.uid}`;
    return `/信用卡比較/${doc.uid}`;
  }

  if (doc && doc.type === 'post_category') {
    return `/post-category/${doc.uid}`;
  }

  if (doc && doc.type === 'post_subcategory') {
    return `/post-category/${getPostCategoryUidBySubcategory(doc) || "general"}/${doc.uid}`;
  }

  if (doc && doc.type === 'offer_category') {
    return `/offer-category/${doc.uid}`;
  }

  if (doc && doc.type === 'offer_subcategory') {
    return `/offer-category/${getOfferCategoryUidBySubcategory(doc) || "general"}/${doc.uid}`;
  }

  if (doc && doc.type === 'tag') {
    return `/tag/${doc.uid}`;
  }
  // Homepage route fallback
  return '/';
};
