import flatten from './common/flatten';
import parseListOrSingle from './parse-list-or-single';

const parseSingle = (options = {}) => ({ data, ...rest }) => {
  const doc = flatten({
    ...rest,
    ...data,
  }, options);

  return doc;
};

export default (item, options) => parseListOrSingle(parseSingle(options))(item);
