import { persist } from 'easy-peasy';
import emailPopup from './email-popup'

import offerSearch from './offer-search';
import session from './session';
import userModel from './user-model';
import visitorModel from './visitor-model'
import allCreditCards from './all-credit-cards'

const storeModel = {
  emailPopup: persist(emailPopup),
  offerSearch: persist(offerSearch),
  session: persist(session),
  user: userModel,
  visitor: visitorModel,
  allCreditCards,
}


export default storeModel;
