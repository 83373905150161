import Prismic from 'prismic-javascript'
import query from './helpers/query'

const fields = [
  'neighborhood.id',
  'neighborhood.name',
  'neighborhood.region',
]

const getSearchNeighborhoods = (ids) => {

  let predicates = [
    Prismic.Predicates.at('document.type', 'neighborhood'),
    Array.isArray(ids) ? Prismic.Predicates.in('document.id', ids) : Prismic.Predicates.at('document.id', ids)
  ]

  return query(
    predicates,
    {
      fetch: fields,
    },
  )
}

export default getSearchNeighborhoods
