import React from 'react';
import styled from 'styled-components';
import MuiSnackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '../IconButton';

// eslint-disable-next-line prefer-arrow-callback
const Snackbar = styled(React.forwardRef(function Snackbar({ onClose = () => null, children, ...props }, ref) {
  return (
    <MuiSnackbar
      ref={ref}
      onClose={onClose}
      action={(
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      )}
      {...props}
    >
      {children}
    </MuiSnackbar>
  )
}))`

`

Snackbar.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: 3000,
}

export default Snackbar
