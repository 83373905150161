/* eslint-disable import/prefer-default-export */
import React from 'react'
import ReactDOM from 'react-dom'
import Message from '@components/Message'
import { removeToken } from '@utils/auth'
import urljoin from 'url-join'

const windowGlobal = typeof window !== 'undefined' && window

export const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = windowGlobal ? window.location.search.substr(1).match(reg) : null /* eslint-env browser */
  if (r != null) {
    return decodeURI(r[2])
  }
  return null
}

export const delParam = (paramKey) => {
  // let url = window.location.href
  // const beforeUrl = url.substr(0, url.indexOf("?"))
  const urlParam = windowGlobal ? window.location.search.substr(1) : null
  let nextUrl = "";

  const arr = []
  if (urlParam !== "") {
    const urlParamArr = urlParam.split("&")
    for (let i = 0; i < urlParamArr.length; i += 1) {
      const paramArr = urlParamArr[i].split("=")
      if (!paramKey.includes(paramArr[0])) {
        arr.push(urlParamArr[i])
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = `?${arr.join("&")}`;
  }
  return nextUrl
}

export const getQueryArray = () => {
  const urlParam = windowGlobal ? window.location.search : null
  const objURL = []
  if (urlParam !== null) {
    urlParam.replace(
      new RegExp("([^?=&]+)(=([^&]*))?", "g"),
      function ($0, $1, $2, $3) {
        if (objURL[$1] && !Array.isArray(objURL[$1])) {
          // if there parameter occurs more than once, convert to an array on 2nd
          const first = objURL[$1]
          objURL[$1] = [first, $3]
        } else if (objURL[$1] && Array.isArray(objURL[$1])) {
          // if there parameter occurs more than once, add to array after 2nd
          objURL[$1].push($3)
        } else {
          // this is the first instance
          objURL[$1] = $3
        }
      }
    )
  }
  return objURL
}

export const redirectToSSOURL = () => {
  if (windowGlobal) {
    const currentUrl = decodeURI(window.location.href)
    removeToken()
    window.location.href = urljoin(process.env.GATSBY_NMG_AUTH_URL, `login?app_id=${process.env.GATSBY_NMG_AUTH_APP_ID}&redirect_url=${currentUrl}`)
  }
}

export const toast = (msg, callback = () => { }) => {
  const div = document.createElement('div')
  div.setAttribute('id', 'response-toast')
  document.body.appendChild(div)
  ReactDOM.render(
    <Message open severity="error" message={msg} />,
    document.getElementById('response-toast') /* eslint-env browser */
  )
  setTimeout(() => {
    callback()
  }, 1000)
}
