import Prismic from 'prismic-javascript'
import query from './helpers/query'


const fields = [
  'post.id',
  'post.lead',
  'post.post_category',
  'post.post_subcategory',
  'post.featured_images',
  'post.tagged',
  'post.title',
  'post.author',
]

const linkedFields = [
  'post_category.name',
  'post_subcategory.name',
  'post_subcategory.post_category',
  'tag.name',
  'author.name',
]


const richTextFields = [
  {
    path: ['short_description'],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ['lead'],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
]

const getPosts = ({
  filter,
  options,
  extensions,
} = {}) => {
  const {
    postCategoryId,
    postSubcategoryId,
    tagId,
    lastPublicationDate,
    exclude = [],
  } = filter || {}
  let predicates = [
    Prismic.Predicates.at('document.type', 'post'),
  ]

  if (postCategoryId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.post.post_category', postCategoryId),
    ]
  }

  if (postSubcategoryId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.post.post_subcategory', postSubcategoryId),
    ]
  }

  if (tagId) {
    predicates = [
      ...predicates,
      Prismic.Predicates.at('my.post.tagged.tag', tagId),
    ]
  }

  if (lastPublicationDate) {
    const beforeDate = new Date(lastPublicationDate);
    beforeDate.setSeconds(beforeDate.getSeconds() + 1);
    predicates = [
      ...predicates,
      Prismic.Predicates.dateBefore('document.last_publication_date', beforeDate),
    ]
  }

  if (exclude) {
    const excludePredicates = exclude.map((id) => Prismic.Predicates.not('document.id', id))
    predicates = [
      ...predicates,
      ...excludePredicates,
    ]
  }

  return query(
    predicates,
    {
      orderings: '[my.post.date desc, document.last_publication_date desc]',
      fetch: fields,
      fetchLinks: linkedFields,
      ...options,
    },
    {
      parseRichText: richTextFields,
      ...extensions,
    }
  )
}

export default getPosts
