import { compose, when, has, prop, pipe, map, ifElse, useWith, identity, equals, isEmpty, isNil, either, not, allPass, reject } from 'ramda';
import isArray from './is-array';
import isObject from './is-object';

const isDocument = useWith(equals, [identity, prop('link_type')])('Document')

const isEmptyDocument = allPass([isDocument, compose(not, has('id'))])

const flattenDocumentKey = when(has('document'), ({ document, ...rest }) => ({
  ...rest,
  ...document,
}));

const flattenDataKey = when(has('data'), ({ data, ...rest }) => ({
  ...rest,
  ...data,
}));

const flattenEmptyDocument = when(isEmptyDocument, () => ({}));

const flattenField = pipe(
  flattenDocumentKey,
  flattenDataKey,
  flattenEmptyDocument
);

const isArrayOrObject = either(isArray, isObject);

const isEmptyOrNil = either(isEmpty, isNil);

const isGatsbyImageObject = allPass([has('src'), has('srcSet'), has('aspectRatio'), has('sizes')]);

const notGatsbyImageObject = useWith(not, [isGatsbyImageObject]);

const mapFlattenField = useWith(map, [identity, flattenField]);

const rejectEmptyAndNil = useWith(reject, [identity, identity])(isEmptyOrNil);

const flatten = (value, options = {}) => {
  const { transform = identity } = options;

  const flattenAll = (val) => ifElse(
    allPass([isArrayOrObject, notGatsbyImageObject]),
    compose(
      rejectEmptyAndNil,
      transform,
      mapFlattenField(flattenAll)
    ),
    identity
  )(val)

  return flattenAll(value)
};

export default flatten;
